<template>
  <div>
    <v-btn @click="statusOfDialog = true">Click để mở</v-btn>
    <ContentDialog
      :itemName="itemName"
      :actionName="actionName"
      :pretext="pretext"
      :surtext="surtext"
      :statusOfDialog="statusOfDialog"
      @closeDialog="closeDialog"
      :leftButton="leftButton"
      :rightButton="rightButton"
    />
  </div>
</template>

<script>
import ContentDialog from './ContentDialog';
export default {
  components: {
    ContentDialog,
  },
  data() {
    return {
      pretext: 'Bạn có chắc chắn muốn',
      actionName: 'xóa',
      itemName: 'Trung',
      surtext: 'không ?',
      statusOfDialog: false,
      leftButton: 'Hủy',
      rightButton: 'Đồng ý',
    };
  },
  methods: {
    closeDialog() {
      this.statusOfDialog = false;
    },
  },
};
</script>

<style></style>
